.page-type-brand .brand:nth-child(6n+1) {
clear:left;
padding-bottom:10px;
padding-top:10px;
}

.page-type-brand.brand .item {
padding-bottom:0;
}

.page-type-brand .brand .brand-image {
max-height:100px;
}
