@charset "UTF-8";
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
.nav-header-container {
  border-bottom: 1px solid #ddd;
}
.nav-header-container nav.nav-container {
  background-color: rgba(255, 255, 255, 0.9);
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu {
  display: block !important;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0:hover > a {
  background-color: #006253;
  color: #fff;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0:hover .caret {
  border-top-color: #fff;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a {
  font-weight: 500;
  line-height: 45px;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  color: #23221d;
}
@media (min-width: 960px) and (max-width: 1279px) {
  .nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a {
    font-size: 14px;
  }
}
@media (min-width: 960px) and (max-width: 1099px) {
  .nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a {
    font-size: 12px;
  }
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 > a .caret {
  border-top-color: #23221d;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 .nav-panel--dropdown {
  border-top: 2px solid #006253;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 .nav-panel--dropdown a {
  font-size: 14px;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.level0 .nav-panel--dropdown a:hover {
  color: #006253;
}
.nav-header-container nav.nav-container #nav.nav-regular.opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu li.nav-item.nav-item--home.level0 {
  display: none;
}

@media (min-width: 1280px) {
  #root-wrapper header#header .logo-column .logo-wrapper {
    left: -68% !important;
  }
  #root-wrapper header#header .logo-column .logo-wrapper .logo {
    max-width: 145px;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  #root-wrapper header#header .logo-column .logo-wrapper .logo {
    max-width: 100px;
  }
}
@media (min-width: 960px) {
  #root-wrapper header#header {
    position: fixed;
    width: 100%;
    z-index: 9998;
  }
  #root-wrapper header#header .top-header-container {
    background-color: #4c4c4c;
    font-size: 14px;
    padding: 5px 0;
  }
  #root-wrapper header#header .top-header-container .nested-container {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
    display: block;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container {
    display: flex;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .grid12-1 {
    width: auto;
    width: initial;
    width: intrinsic;
    width: -moz-max-content;
    width: -webkit-max-content;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .userSwitch {
    margin-left: 5px;
    display: inline-block;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .userSwitch a {
    text-indent: -9999px;
    display: inline-block;
    color: transparent;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .userSwitch a:hover {
    color: transparent;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .userSwitch a::after {
    text-indent: 0;
    float: left;
    font-family: "fontAwesome";
    color: #d86827;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .userSwitch a::after {
    content: "";
    float: right;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container p, #root-wrapper header#header .top-header-container .nested-container .grid-container span {
    margin: 0;
    line-height: 34px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container a, #root-wrapper header#header .top-header-container .nested-container .grid-container a:hover {
    font-weight: bold;
    color: #d86827;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .module-switch-language .dropdown.lang-switcher:not(.open) div {
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .module-switch-language .dropdown.lang-switcher:not(.open) .caret {
    border-top-color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .module-switch-language .dropdown.lang-switcher .caret {
    display: inline-block;
    margin-left: 3px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .module-switch-language .dropdown.lang-switcher .dropdown-toggle.cover {
    line-height: 34px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .module-switch-language .dropdown.lang-switcher .dropdown-menu {
    z-index: 9999;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li {
    line-height: 34px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a {
    text-indent: -9999px;
    display: inline-block;
    color: transparent;
    font-size: 16px;
    padding: 0 5px;
    margin: 0 5px;
    line-height: 34px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a:hover {
    color: transparent;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a::after {
    text-indent: 0;
    float: left;
    font-family: "fontAwesome";
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a.link-account::after {
    content: "";
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a.link-admin::after {
    content: "";
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a.link-logout::after {
    content: "";
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a.link-login::after {
    content: "";
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a.link-register::after {
    content: "";
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .links > li > a:hover {
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a {
    line-height: 34px;
    font-size: 14px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a div {
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a span {
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a span.fa {
    color: #fff !important;
    font-size: 18px;
    padding: 0;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a .empty, #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover > a .hide-below-960 {
    display: none;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-toggle .feature-icon-hover .caret {
    border-top-color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-menu {
    color: #23221d;
    z-index: 9999;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart .dropdown-menu button.button span span {
    padding: 5px 13px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart.open .dropdown-toggle .feature-icon-hover > a div {
    color: #23221d;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart.open .dropdown-toggle .feature-icon-hover > a span {
    color: #23221d;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart.open .dropdown-toggle .feature-icon-hover > a span.fa {
    color: #23221d !important;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart.open .dropdown-toggle .feature-icon-hover > a:hover span.fa::before {
    color: #23221d !important;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container #mini-cart.open .dropdown-toggle .feature-icon-hover .caret {
    border-top-color: #23221d;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column {
    margin: 0 auto;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column .module-search.search-wrapper {
    margin-top: 0;
    width: 100%;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column .module-search.search-wrapper .form-search .input-text {
    padding-right: 34px !important;
    height: 34px;
    line-height: 34px;
    box-shadow: 0 0 2px #fff;
    color: #fff !important;
    font-size: 14px;
    float: none;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column .module-search.search-wrapper .form-search .input-text::placeholder {
    color: #aaa;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column .module-search.search-wrapper .form-search .input-text:hover::placeholder {
    color: #fff;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .search-column .module-search.search-wrapper .form-search .button-search .fa {
    color: #fff;
    padding: 9px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .dropdown-menu li {
    clear: left;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .dropdown-menu li span {
    line-height: 21px;
    float: left;
    margin-right: 5px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .dropdown-menu li a {
    float: left;
    color: #23221d;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .label.dropdown-icon {
    display: none;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .label.dropdown-icon + * {
    text-indent: -9999px;
    width: 19px;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .label.dropdown-icon + *::after {
    text-indent: 0;
    float: left;
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .label.dropdown-icon[style*="us.png"] + *::after {
    content: "EN";
  }
  #root-wrapper header#header .top-header-container .nested-container .grid-container .dropdown.lang-switcher.item .label.dropdown-icon[style*="fr.png"] + *::after {
    content: "FR";
  }
  #root-wrapper header#header .main-header-container {
    background-color: rgba(255, 255, 255, 0.9);
    color: #23221d;
    line-height: 46px;
    border-bottom: 1px solid #ddd;
  }
  #root-wrapper header#header .main-header-container .nested-container {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
    display: block;
  }
  #root-wrapper header#header .main-header-container .nested-container .logo-column {
    position: absolute;
    left: 50%;
    z-index: 10000;
  }
  #root-wrapper header#header .main-header-container .nested-container .logo-column .logo-wrapper {
    position: relative;
    left: -50%;
    top: 4px;
    margin: 0;
  }
  #root-wrapper header#header .main-header-container .nested-container .logo-column .logo-wrapper .logo strong {
    display: none;
  }
}
@media (max-width: 959px) {
  #root-wrapper header#header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
  }
  #root-wrapper header#header .grid12-12, #root-wrapper header#header .grid-full {
    overflow: hidden;
  }
  #root-wrapper header#header .mobile-header-container {
    float: none;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column {
    margin: 0;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container {
    border-bottom: 2px solid #006253;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container span.fa::before {
    color: #23221d;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .item-logo {
    position: absolute;
    left: 50%;
    line-height: 0;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .item-logo .logo-wrapper {
    position: relative;
    left: -50%;
    margin: 0;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .item-logo .logo-wrapper a.logo {
    max-width: 70px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .item-logo .logo-wrapper a.logo img {
    width: 100%;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .nav-cart .hide-below-960 {
    display: none !important;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .nav-cart .hide-below-960 .cart-total {
    color: #006253;
    font-weight: bold;
    font-size: 14px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .nav-search.fly-out {
    background-color: rgba(255, 255, 255, 0.9);
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .module-switch-language * {
    margin: 0 !important;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .module-switch-language .dropdown.lang-switcher .caret {
    display: inline-block;
    margin-left: 3px !important;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .module-switch-language .dropdown.lang-switcher .dropdown-toggle.cover {
    line-height: 41px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .module-switch-language .dropdown.lang-switcher .dropdown-menu {
    z-index: 9999;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .dropdown-menu {
    position: fixed;
    top: 41px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .dropdown-menu li {
    clear: left;
    width: 19px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .dropdown-menu li span {
    line-height: 21px;
    float: left;
    margin-right: 5px;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .dropdown-menu li a {
    float: left;
    color: #23221d;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .label.dropdown-icon {
    display: none;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .label.dropdown-icon + * {
    text-indent: -9999px;
    color: transparent;
    margin-bottom: 12px !important;
    font-weight: bold;
    width: 16px;
    visibility: hidden;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .label.dropdown-icon + *::after {
    text-indent: 0;
    float: left;
    color: #23221d;
    visibility: visible;
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .label.dropdown-icon[style*="us.png"] + *::after {
    content: "EN";
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .dropdown.lang-switcher.item .label.dropdown-icon[style*="fr.png"] + *::after {
    content: "FR";
  }
  #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container .form-search .button, #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container span.fa, #root-wrapper header#header .mobile-header-container .mobile-nav-column .header-top-container a:hover span.fa::after {
    color: #23221d !important;
  }
}

/* ==== FRENCH LANGUAGE HEADER === */
@media (min-width: 1280px) {
  html[lang=fr] #root-wrapper header#header .logo-column .logo-wrapper {
    left: -50% !important;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  html[lang=fr] #root-wrapper header#header .logo-column .logo-wrapper {
    left: -10% !important;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1399px) {
  html[lang=fr] #root-wrapper header#header .logo-column .logo-wrapper {
    left: -10% !important;
  }
}

@media (min-width: 960px) {
  .page-type-home .main.container {
    padding-top: 90px !important;
  }
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
@media (min-width: 960px) {
  body .product-search-container .page-title.category-title {
    position: absolute;
    color: #fff;
    z-index: 1000;
    text-shadow: 0 0 10px #23221d;
    top: 0;
    left: 0;
  }
}
body .product-search-container .pager .pages li {
  border-radius: 3px;
}
body .product-search-container .pager .pages li.current {
  background-color: #d86827;
  color: #fff;
}
body .product-search-container .pager .pages li a {
  border-radius: 3px;
}
body .product-search-container .pager .pages li a:hover {
  background-color: #d86827;
  color: #fff;
}
body .product-search-container .mobile-bottom-pagination .module-category-misc {
  display: flex;
  justify-content: center;
}
body .product-search-container .sorter .view-mode span {
  border-radius: 3px;
}
body .product-search-container .sorter .view-mode span.list.active {
  background-color: #d86827;
  background-position-y: -237px;
}
body .product-search-container .category-products-grid.hover-effect .item, body .product-search-container .products-list.hover-effect .item {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-left: 0.5%;
  padding-right: 0.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}
body .product-search-container .category-products-grid.hover-effect .item:hover, body .product-search-container .products-list.hover-effect .item:hover {
  box-shadow: 0px 10px 15px #ddd;
  margin-bottom: 21px;
  border-radius: 10px;
  border-bottom: none;
}
body .product-search-container .category-products-grid.hover-effect .item .qty-wrapper, body .product-search-container .products-list.hover-effect .item .qty-wrapper {
  width: 209px;
  margin-bottom: 5px;
}
body .product-search-container .category-products-grid.hover-effect .item .qty-wrapper input, body .product-search-container .products-list.hover-effect .item .qty-wrapper input {
  width: 100% !important;
  height: 30px;
}
body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item .stock-status-column, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item .stock-status-column {
  position: absolute;
  left: 33.33%;
}
@media (min-width: 960px) {
  body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item .stock-status-column, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item .stock-status-column {
    top: 100px;
  }
}
@media (max-width: 959px) {
  body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item .stock-status-column, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item .stock-status-column {
    top: 150px;
  }
}
body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item .stock-status-column .stock-status-box .stock-status div, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item .stock-status-column .stock-status-box .stock-status div {
  font-weight: bold;
  font-size: 14px;
}
body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item .qty-wrapper, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item .qty-wrapper {
  width: 100%;
}
body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item button.button.btn-cart.add-cart-button, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item button.button.btn-cart.add-cart-button {
  width: 100%;
}
body .product-search-container .category-products-grid.hover-effect.products-list.hover-effect .item button.button.btn-cart.add-cart-button span span, body .product-search-container .products-list.hover-effect.products-list.hover-effect .item button.button.btn-cart.add-cart-button span span {
  padding: 13px 0;
}
body .product-search-container .category-products-grid.hover-effect .item .price {
  float: none;
  display: block;
}
body .product-search-container .category-products-grid.hover-effect .item .qty-wrapper {
  margin-left: auto;
  margin-right: auto;
  float: none !important;
  display: block;
}
body .product-search-container .category-products-grid.hover-effect .item button.button.btn-cart.add-cart-button {
  margin-left: auto;
  margin-right: auto;
  float: none !important;
  display: block;
}
body .product-search-container .category-list-tab.item_tab1 {
  display: none;
}
@media (min-width: 960px) {
  body .product-search-container .no-gutter.category-banner .nested-container {
    width: 104%;
    margin-left: -2%;
  }
}
@media (max-width: 959px) {
  body .product-search-container .category-products-grid.hover-effect .item .qty-wrapper {
    width: 130px;
  }
}
body[data-product-search-total-items="0"] .page-title.category-title {
  position: relative;
  color: #23221d;
  z-index: auto;
  text-shadow: none;
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
.product-view .product-shop .std, .xm-grid-product .product-shop .std {
  color: #23221d;
}
.product-view .product-shop .product-type-data #stocklevel a.button span, .xm-grid-product .product-shop .product-type-data #stocklevel a.button span {
  background-color: #006253;
  color: #fff;
}
.product-view .product-shop .product-type-data #stocklevel a.button:hover span, .xm-grid-product .product-shop .product-type-data #stocklevel a.button:hover span {
  background-color: #fff;
  color: #006253;
}
.product-view .product-shop .product-type-data .availability, .xm-grid-product .product-shop .product-type-data .availability {
  margin: 5px 0 0 10px;
}
.product-view .product-shop .product-type-data span.icon.icon-color-productview, .xm-grid-product .product-shop .product-type-data span.icon.icon-color-productview {
  background-color: #006253;
}
.product-view .product-shop .product-type-data span.icon.icon-color-productview.i-cart, .xm-grid-product .product-shop .product-type-data span.icon.icon-color-productview.i-cart {
  background-position-y: -57px;
}
.product-view .box-additional.box-tabs .gen-tabs .tabs a, .product-view .box-additional.box-tabs .gen-tabs .tabs h2, .product-view .box-additional.box-tabs .gen-tabs .tabs-panels a, .product-view .box-additional.box-tabs .gen-tabs .tabs-panels h2, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs a, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs h2, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs-panels a, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs-panels h2 {
  color: #ddd;
  padding: 0 30px;
  font-size: 18px;
}
.product-view .box-additional.box-tabs .gen-tabs .tabs a:hover, .product-view .box-additional.box-tabs .gen-tabs .tabs h2:hover, .product-view .box-additional.box-tabs .gen-tabs .tabs-panels a:hover, .product-view .box-additional.box-tabs .gen-tabs .tabs-panels h2:hover, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs a:hover, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs h2:hover, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs-panels a:hover, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs-panels h2:hover {
  color: #d86827;
}
.product-view .box-additional.box-tabs .gen-tabs .tabs a.current, .product-view .box-additional.box-tabs .gen-tabs .tabs h2.current, .product-view .box-additional.box-tabs .gen-tabs .tabs-panels a.current, .product-view .box-additional.box-tabs .gen-tabs .tabs-panels h2.current, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs a.current, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs h2.current, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs-panels a.current, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs-panels h2.current {
  color: #23221d;
}
.product-view .box-additional.box-tabs .gen-tabs .tabs .acctab, .product-view .box-additional.box-tabs .gen-tabs .tabs-panels .acctab, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs .acctab, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs-panels .acctab {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}
.product-view .box-additional.box-tabs .gen-tabs .tabs .acctab::after, .product-view .box-additional.box-tabs .gen-tabs .tabs-panels .acctab::after, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs .acctab::after, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs-panels .acctab::after {
  font-family: "fontAwesome";
  position: absolute;
  right: 30px;
  content: "";
}
.product-view .box-additional.box-tabs .gen-tabs .tabs .acctab.current::after, .product-view .box-additional.box-tabs .gen-tabs .tabs-panels .acctab.current::after, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs .acctab.current::after, .xm-grid-product .box-additional.box-tabs .gen-tabs .tabs-panels .acctab.current::after {
  content: none;
}
@media (min-width: 960px) {
  .product-view, .xm-grid-product {
    padding-top: 20px;
  }
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
footer#footer {
  background-color: #23221d;
}
footer#footer .footer-container2 > div[class*=footer-] {
  display: block;
  clear: both;
  float: none;
}
footer#footer .footer-container2 .footer-top-container {
  background-image: url(https://cdn.mysagestore.com/7fe73e2125e5ed677797d269f269f645/contents/ckfinder/images/footer/footerv2a.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
footer#footer .footer-container2 .footer-top-container .footer-top.footer.container {
  margin: 0;
  width: 100%;
}
footer#footer .footer-container2 .footer-top-container .footer-top.footer.container .footer-logo-column img {
  display: block;
  margin: auto;
}
@media (min-width: 960px) {
  footer#footer .footer-container2 .footer-top-container .footer-top.footer.container .footer-logo-column img {
    width: 50%;
  }
}
@media (max-width: 959px) {
  footer#footer .footer-container2 .footer-top-container .footer-top.footer.container .footer-logo-column img {
    width: 70%;
  }
}
footer#footer .footer-container2 .footer-top-container h6.block-title.heading {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}
footer#footer .footer-container2 .footer-top-container ul li a {
  color: #fff;
  font-size: 12px;
}
@media (min-width: 960px) and (max-width: 1279px) {
  footer#footer .footer-container2 .footer-top-container h6.block-title.heading {
    font-size: 10px;
  }
}
@media (min-width: 768px) and (max-width: 959px) {
  footer#footer .footer-container2 .footer-top-container h6.block-title.heading {
    font-size: 8px;
  }
  footer#footer .footer-container2 .footer-top-container ul {
    font-size: 10px;
  }
}
footer#footer .footer-container2 .footer-primary-container h6.block-title.heading {
  color: #fff;
  font-weight: bold;
}
footer#footer .footer-container2 .footer-primary-container .social-links.icon-wrapper-square {
  text-align: right;
}
footer#footer .footer-container2 .footer-primary-container .item.item-right.newsletter-wrapper {
  float: left;
  margin: 0;
}
footer#footer .footer-container2 .footer-primary-container .item.item-right.newsletter-wrapper #subscribe-form .button.btn-inline.newsletter-button {
  float: left;
}
footer#footer .footer-container2 .footer-primary-container .item.item-right.newsletter-wrapper #subscribe-form .button.btn-inline.newsletter-button span span {
  padding: 13px;
}
footer#footer .footer-container2 .footer-primary-bottom.container {
  background-color: #4c4c4c;
}
footer#footer .footer-container2 .footer-primary-bottom.container .footer-primary-bottom-spacing {
  padding: 10px 0;
}
footer#footer .footer-container2 .footer-primary-bottom.container .footer-primary-bottom-spacing ul li a {
  color: #d86827;
  font-weight: bold;
}
footer#footer .footer-container2 .footer-bottom-container {
  display: none !important;
}
footer#footer .footer-container2 .footer-bottom-container .section.clearer {
  display: none;
}
footer#footer .footer-container2 .footer {
  /* Back link */
  /* Slider arrows */
  /* Slider pagination */
  /* deprecated */
  /* Accordion and collapsible: opener */
}
footer#footer .footer-container2 .footer .buttons-set .back-link small {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #006253;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .buttons-set .back-link:hover small {
  background-color: #fff;
  color: #006253;
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls {
  /* Slider pagination */
  /* Slider arrows */
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls .owl-page span, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls .owl-page span, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls .owl-page span, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls .owl-page span, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls .owl-page span {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #006253;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls.clickable .owl-page:hover span, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls.clickable .owl-page:hover span, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls.clickable .owl-page:hover span, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls.clickable .owl-page:hover span, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls.clickable .owl-page:hover span {
  background-color: #fff;
  color: #006253;
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls.clickable .owl-buttons div:hover, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls.clickable .owl-buttons div:hover, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls.clickable .owl-buttons div:hover, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls.clickable .owl-buttons div:hover, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls.clickable .owl-buttons div:hover {
  background-color: #fff;
  color: #006253;
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls.clickable .owl-buttons div.disabled:hover, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls.clickable .owl-buttons div.disabled:hover, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls.clickable .owl-buttons div.disabled:hover, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls.clickable .owl-buttons div.disabled:hover, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls.clickable .owl-buttons div.disabled:hover {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #006253;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .slider-arrows1 .owl-controls .owl-buttons div, footer#footer .footer-container2 .footer .slider-arrows2 .owl-controls .owl-buttons div, footer#footer .footer-container2 .footer .slider-arrows3 .owl-controls .owl-buttons div, footer#footer .footer-container2 .footer .slider-pagination1 .owl-controls .owl-buttons div, footer#footer .footer-container2 .footer .slider-pagination2 .owl-controls .owl-buttons div {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #006253;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .gen-slider-arrows1 .direction-nav a, footer#footer .footer-container2 .footer .gen-slider-arrows2 .direction-nav a, footer#footer .footer-container2 .footer .gen-slider-arrows3 .direction-nav a {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #006253;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .gen-slider-arrows1 .direction-nav a.disabled:hover, footer#footer .footer-container2 .footer .gen-slider-arrows2 .direction-nav a.disabled:hover, footer#footer .footer-container2 .footer .gen-slider-arrows3 .direction-nav a.disabled:hover {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #006253;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .gen-slider-arrows1 .direction-nav a:hover, footer#footer .footer-container2 .footer .gen-slider-arrows2 .direction-nav a:hover, footer#footer .footer-container2 .footer .gen-slider-arrows3 .direction-nav a:hover {
  background-color: #fff;
  color: #006253;
}
footer#footer .footer-container2 .footer .accordion-style1 .opener, footer#footer .footer-container2 .footer .collapsible .opener {
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #006253;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
footer#footer .footer-container2 .footer .accordion-style1 .opener:hover, footer#footer .footer-container2 .footer .collapsible .opener:hover {
  background-color: #fff;
  color: #006253;
}
@media only screen and (min-width: 768px) {
  footer#footer .footer-container2 .footer .footer-social-column {
    max-width: 19%;
  }
}
@media (max-width: 767px) {
  footer#footer .footer-container2 .footer .footer-social-column .item-right {
    float: none;
    margin: 0;
  }
}
footer#footer .footer-container2 .footer .footer-social-column .item-right .social-links.icon-wrapper-circle {
  text-align: right;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  footer#footer .footer-container2 .footer .footer-social-column .item-right .social-links.icon-wrapper-circle {
    justify-content: flex-end;
  }
}
footer#footer .footer-container2 .footer .footer-social-column .item-right .social-links.icon-wrapper-circle a {
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  margin-left: 5px;
  width: 50px;
  height: 50px;
}
footer#footer .footer-container2 .footer .footer-social-column .item-right .social-links.icon-wrapper-circle a i.fa {
  line-height: 50px;
  font-size: 30px;
}
@media (max-width: 959px) {
  footer#footer .footer-container2 .footer-primary-bottom.container {
    padding: 0;
  }
  footer#footer .footer-container2 .collapsible .opener {
    margin-right: 15px;
  }
}

body {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  /* Default icon */
  /* Toolbar */
  /* Back link */
  /* Add-to links */
  /* Slider arrows */
  /* Slider pagination */
  /* Lightbox */
  /* Accordion and collapsible: opener */
  /* Scroll to top */
}
body .keep-width.grid12-1 {
  width: 6.33% !important;
}
body .keep-width.grid12-2 {
  width: 14.66% !important;
}
body .keep-width.grid12-3 {
  width: 23% !important;
}
body .keep-width.grid12-4 {
  width: 31.33% !important;
}
body .keep-width.grid12-5 {
  width: 39.67% !important;
}
body .keep-width.grid12-6 {
  width: 48% !important;
}
body .keep-width.grid12-7 {
  width: 56.33% !important;
}
body .keep-width.grid12-8 {
  width: 64.67% !important;
}
body .keep-width.grid12-9 {
  width: 73% !important;
}
body .keep-width.grid12-10 {
  width: 81.33% !important;
}
body .keep-width.grid12-11 {
  width: 89.67% !important;
}
body .mm-wrapper__blocker.mm-slideout {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: inset 0 -20px 20px #23221d;
}
body .mm-menu {
  background-color: #4c4c4c;
}
body .mm-menu div {
  background-color: transparent;
  border-color: #fff;
}
body .mm-menu a, body .mm-menu a:active, body .mm-menu a:hover, body .mm-menu a:link, body .mm-menu a:visited {
  color: #fff;
}
body .mm-menu a .fa.fa-external-link, body .mm-menu a:active .fa.fa-external-link, body .mm-menu a:hover .fa.fa-external-link, body .mm-menu a:link .fa.fa-external-link, body .mm-menu a:visited .fa.fa-external-link {
  color: #d86827;
  margin-right: 5px;
}
body .mm-menu .mm-listitem:after {
  border-color: #fff;
}
body .mm-menu .mm-btn_next:after, body .mm-menu .mm-btn_prev:before {
  border-color: #d86827;
}
body a {
  color: #23221d;
}
body a:hover {
  color: #006253;
}
body button.button span, body a.button span {
  border-radius: 3px;
}
body button.button span span, body a.button span span {
  padding: 13px 54px;
  border: 2px solid #006253;
  border-radius: 3px;
  background-color: #006253;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: auto;
}
body button.button span span:hover, body a.button span span:hover {
  background-color: #fff;
  color: #006253;
}
body input.input-text {
  height: 45px;
}
body .section-title, body h1, body h2, body h3, body h3.product-name, body h4, body h5, body h6 {
  font-family: "Oswald", Helvetica, Arial, sans-serif;
}
body .nested-container .grid12-12.no-gutter .page-content p img {
  width: 100%;
}
body .collapsible.active .opener {
  background-image: url(https://commercebuild-themes.mysagestore.com/static/vortex/images/opener-active-w.png);
}
body .collapsible.active .opener:hover {
  background-image: url(https://commercebuild-themes.mysagestore.com/static/vortex/images/opener-active.png);
}
body .collapsible .opener {
  background-image: url(https://commercebuild-themes.mysagestore.com/static/vortex/images/opener-w.png);
}
body .collapsible .opener:hover {
  background-image: url(https://commercebuild-themes.mysagestore.com/static/vortex/images/opener.png);
}
body .sorter .view-mode span.grid, body .sorter .view-mode span.list, body .sorter .sort-by span.grid, body .sorter .sort-by span.list {
  background-color: #ddd;
  border-radius: 3px;
}
body .sorter .view-mode a, body .sorter .view-mode .category-asc, body .sorter .view-mode .category-desc, body .sorter .sort-by a, body .sorter .sort-by .category-asc, body .sorter .sort-by .category-desc {
  background-color: #ddd;
  border-radius: 3px;
}
body .sorter .view-mode a:hover, body .sorter .view-mode .category-asc:hover, body .sorter .view-mode .category-desc:hover, body .sorter .sort-by a:hover, body .sorter .sort-by .category-asc:hover, body .sorter .sort-by .category-desc:hover {
  background-color: #ddd;
  border-radius: 3px;
  background-color: #d86827;
}
body .pager .pages li a, body .pager .pages li.current {
  background-color: #ddd;
  border-radius: 3px;
}
body .buttons-set .back-link small {
  background-color: #ddd;
  border-radius: 3px;
}
body .buttons-set .back-link:hover small {
  background-color: #ddd;
  border-radius: 3px;
  background-color: #d86827;
}
body .add-to-links {
  /* Simple icons (product grid) */
  /* Other with standard icons */
}
body .add-to-links.addto-icons a {
  background-color: #ddd;
  border-radius: 3px;
}
body .add-to-links.addto-icons a:hover {
  background-color: #ddd;
  border-radius: 3px;
  background-color: #d86827;
}
body .add-to-links span.icon {
  background-color: #ddd;
  border-radius: 3px;
}
body .add-to-links span.icon-hover:hover {
  background-color: #ddd;
  border-radius: 3px;
  background-color: #d86827;
}
body .add-to-links .feature-icon-hover:hover span.icon {
  background-color: #ddd;
  border-radius: 3px;
  background-color: #d86827;
}
body .slider-arrows1.owl-controls .owl-buttons div, body .slider-arrows2.owl-controls .owl-buttons div, body .slider-arrows3.owl-controls .owl-buttons div {
  background-color: #ddd;
  border-radius: 3px;
}
body .slider-arrows1.owl-controls.clickable .owl-buttons div.disabled:hover, body .slider-arrows2.owl-controls.clickable .owl-buttons div.disabled:hover, body .slider-arrows3.owl-controls.clickable .owl-buttons div.disabled:hover {
  background-color: #ddd;
  border-radius: 3px;
}
body .slider-arrows1.owl-controls.clickable .owl-buttons div:hover, body .slider-arrows2.owl-controls.clickable .owl-buttons div:hover, body .slider-arrows3.owl-controls.clickable .owl-buttons div:hover {
  background-color: #ddd;
  border-radius: 3px;
  background-color: #d86827;
}
body .gen-slider-arrows1 .direction-nav a, body .gen-slider-arrows2 .direction-nav a, body .gen-slider-arrows3 .direction-nav a {
  background-color: #ddd;
  border-radius: 3px;
}
body .gen-slider-arrows1 .direction-nav a.disabled:hover, body .gen-slider-arrows2 .direction-nav a.disabled:hover, body .gen-slider-arrows3 .direction-nav a.disabled:hover {
  background-color: #ddd;
  border-radius: 3px;
}
body .gen-slider-arrows1 .direction-nav a:hover, body .gen-slider-arrows2 .direction-nav a:hover, body .gen-slider-arrows3 .direction-nav a:hover {
  background-color: #ddd;
  border-radius: 3px;
  background-color: #d86827;
}
body .slider-pagination1 .owl-controls .owl-page span, body .slider-pagination1 .owl-controls .owl-page.active span, body .slider-pagination2 .owl-controls .owl-page span, body .slider-pagination2 .owl-controls .owl-page.active span {
  background-color: #ddd;
  border-radius: 3px;
}
body .slider-pagination1 .owl-controls .owl-page:hover span, body .slider-pagination1 .owl-controls .owl-page.active:hover span, body .slider-pagination2 .owl-controls .owl-page:hover span, body .slider-pagination2 .owl-controls .owl-page.active:hover span {
  background-color: #ddd;
  border-radius: 3px;
  background-color: #d86827;
}
body #cboxPrevious, body #cboxNext, body #cboxClose {
  background-color: #ddd;
  border-radius: 3px;
}
body #cboxPrevious:hover, body #cboxNext:hover, body #cboxClose:hover {
  background-color: #ddd;
  border-radius: 3px;
  background-color: #d86827;
}
body .accordion-style1 .opener, body .collapsible .opener {
  background-color: #ddd;
  border-radius: 3px;
}
body .accordion-style1 .opener:hover, body .collapsible .opener:hover {
  background-color: #ddd;
  border-radius: 3px;
  background-color: #d86827;
}
body #scroll-to-top {
  background-color: #006253;
  border: 2px solid #006253;
  display: none !important;
}
body #scroll-to-top::before {
  font-family: "fontAwesome";
  color: #fff;
  content: "";
  font-size: 30px;
  left: 12px;
  position: absolute;
  top: 15px;
  text-indent: 0;
}
body #scroll-to-top:hover {
  background-color: #fff;
}
body #scroll-to-top:hover::before {
  color: #006253;
}

@media (min-width: 960px) {
  .show-below-960 {
    display: none;
  }

  html .main.container {
    width: 100%;
  }
  html.page-type-home .main.container, html.page-type-category .main.container {
    padding-top: 100px;
  }
}
@media (min-width: 1280px) {
  .main.container {
    padding-top: 152px;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  .main.container {
    padding-top: 120px;
  }
}
@media (max-width: 959px) {
  .hide-below-960 {
    display: none;
  }

  .main.container {
    padding-top: 44px;
  }
}

/*# sourceMappingURL=general.css.map */
