/*
    blog.css for magento ultimo_one theme
    (no specific theme fixes as-yet)
*/
/* 
    Created on : 23/07/2014, 2:20:23 PM
    Author     : bart
*/

.page-type-blog #blogcrumbs ul,
.page-type-blog #blogcrumbs ol {
    margin: 0 0 10px;
    padding: 0;
}
.page-type-blog #breadcrumbs {
    clear: both;
    float: left;
    font-size: 12px;
    line-height: 1.25;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 0;
    text-transform: capitalize;
}
.page-type-blog #breadcrumbs li {
    color: #4d4d4d;
    display: inline;
    margin-right: 5px;
}