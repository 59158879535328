/* User */

.page-type-user .pager {
	border-top: none;
}

/* Buttons
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/
.page-type-user a.button::-moz-focus-inner
{
    padding:0 !important;
    border:0 !important;
} /* FF Fix */
.page-type-user a.button {
    -webkit-border-fit:lines; /* <- Safari & Google Chrome Fix */
    overflow:visible; width:auto; border:0; padding:0; margin:0; background:transparent; cursor:pointer;
    display: inline-block;
}
.page-type-user a.button span {
    float:left;
    display:block;
    text-align:center;
    white-space:nowrap;

    transition:         color 300ms ease-in-out, background-color 300ms ease-in-out;
    -moz-transition:    color 300ms ease-in-out, background-color 300ms ease-in-out;
    -webkit-transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
    -o-transition:      color 300ms ease-in-out, background-color 300ms ease-in-out;
}

.page-type-user #loader {
    display: none;
}

/* gallery images */
.page-type-user .no-right-gutter.grid12-3 {
    margin-right: 0;
    width: 24%;
}
/* saved cart */
.page-type-user #actions_save_cart {
    padding:5px 0;
    border-radius:3px;
    background-color:#ffffff;
    border:1px solid #000000;
    width:148px;
}
.page-type-user #actions_save_cart .savecart_popup {
    border:0 !important;
    background-color:#ffffff !important;
    color:#333333 !important;
    width:118px !important;
}
.page-type-user #actions_save_cart .savecart_popup:hover {
    text-decoration:underline !important;
}

/* Auto-complete */
.page-type-user .ui-autocomplete {
    height: auto !important;
    max-height: 180px !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding-bottom: 7px !important;
}