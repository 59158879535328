/* Simple */
.page-type-simple body         { font:75%/150% Arial, Helvetica, sans-serif; background-color:#fff; color:#333; }

.page-type-simple button.button::-moz-focus-inner {
    border: 0 none;
    padding: 0;
}
.page-type-simple button.button {
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
    cursor: pointer;
    margin: 0;
    overflow: visible;
    padding: 0;
    width: auto;
}
.page-type-simple button.button span {
    background-color: #333333;
    color: #ffffff;
    display: block;
    float: left;
    font-size: 12px;
    padding: 0;
    text-align: center;
    transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s;
    white-space: nowrap;
}
.page-type-simple button.button:hover span {
    background-color: #5bd2ec;
    color: #ffffff;
}
.page-type-simple button.button span span {
    height: auto;
    line-height: 2em;
    padding: 7px 15px;
}
.page-type-simple button.button.search span span {
    padding: 2px 15px !important;
}

.page-type-simple input.input-text,
.page-type-simple select,
.page-type-simple textarea {
    background: none repeat scroll 0 0 #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: #888;
}
.page-type-simple input.input-text,
.page-type-simple textarea {
    padding: 8px;
    text-overflow: ellipsis;
}
.page-type-simple input.input-text {
    height: 36px;
}
.page-type-simple .standard.search input.input-text {
    display: inline-block;
    vertical-align: top;
    height: 28px !important;
}

.page-type-simple #stocklevel {
	float: right;
}

/* Buttons
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

.page-type-simple #stocklevel a.button::-moz-focus-inner
{
    padding:0 !important;
    border:0 !important;
} /* FF Fix */
.page-type-simple #stocklevel a.button {
    -webkit-border-fit:lines; /* <- Safari & Google Chrome Fix */
    overflow:visible; width:auto; border:0; padding:0; margin:0; background:transparent; cursor:pointer;
    display: inline-block;
}
.page-type-simple #stocklevel a.button span {
    float: left;
    display:block;
    padding:0;
    font-size:12px;
    text-align:center;
    white-space:nowrap;
    background-color:#ddd;
    color:#333;

    transition:         color 300ms ease-in-out, background-color 300ms ease-in-out;
    -moz-transition:    color 300ms ease-in-out, background-color 300ms ease-in-out;
    -webkit-transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
    -o-transition:      color 300ms ease-in-out, background-color 300ms ease-in-out;
}
.page-type-simple #stocklevel a.button:hover span
{ background-color:#333; color:#fff; }
a.button span span
{ padding:0 10px; height:45px; line-height:45px; }

/* Smaller button - for inline display with input boxes
-------------------------------------------------------------- */
.page-type-simple #stocklevel a.button.btn-inline span span { padding:0 10px; line-height:36px !important; height:36px !important; }
.page-type-simple #stocklevel a.button span {
    background-color: #eeeeee;
    color: #000;
}


.page-type-simple span.was {
	text-decoration: line-through;
	text-transform: uppercase;
}

.page-type-simple span.now {
	font-weight: bolder;
	text-transform: uppercase;
	clear: both;
	display: block;
	color: red;
}

.page-type-simple .item-feature table.data-table {
	width: 100% !important;
}

.main-container { padding: 10px; }
.main-container h2 {/* font-family: Arial, '​Helvetica', 'sans-serif' */}
.compare-items { border: 1px solid #eee; border-right: none; }
.compare-items table {display: table; table-layout: fixed;}
.compare-items td { padding: 5px; border-right: 1px solid #eee; font-weight: normal;}
.compare-items td:first-child { width: 100px; white-space: nowrap; font-weight: bold; }
.compare-items tr:nth-child(even) { background-color: #efefef; }
.compare-items tr:nth-child(odd) { background-color: #FFF; }
